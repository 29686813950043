import corpus from "./capta-sections/corpus.js"
import saggi from "./capta-sections/saggi.js"
import dubbioNebbia from "./capta-sections/dubbioNebbia.js"
import dubbioProcesso from "./capta-sections/dubbioProcesso.js"
import spazioLuoghi from "./capta-sections/spazioLuoghi.js"
import spazioMetamorfosi from "./capta-sections/spazioMetamorfosi.js"
import spazioMovimento from "./capta-sections/spazioMovimento.js"
import formaElenchi from "./capta-sections/formaElenchi.js"
import formaSequenze from "./capta-sections/formaSequenze.js"
import formaTerzo from "./capta-sections/formaTerzo.js"
import tools from "./capta-sections/tools.js"

const capta = [
  corpus,
  saggi,
  dubbioNebbia,
  dubbioProcesso,
  spazioLuoghi,
  spazioMovimento,
  spazioMetamorfosi,
  formaElenchi,
  formaSequenze,
  formaTerzo,
  tools
];
export default capta;
